/* eslint-disable camelcase */
import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  getCurrentUserToken,
  getCurrentUserFBId,
  getCurrentUserLLToken,
  getFBPageId,
  getCurrentPageId,
  getCurrentUserInstagramId,
  getCurrentUserId,
  getAuthHeaders,
  getCompanyId,
  getCurrentCompanyId,
  getShortLivedToken,
  getCurrentServiceId,
  getNoCompanyAuthHeaders,
  getCurrentServiceIGId,
} from './firebase';


const fbApiUrl = 'https://graph.facebook.com/v6.0';
// const fbApiOld = 'https://graph.facebook.com/v3.1';
// const fbOld = 'https://graph.facebook.com/3.1';
// const herokuApi = 'https://reach-hooks.herokuapp.com'
const thirtyDaysUnix = 60 * 60 * 24 * 30;

const newHerokuApi = process.env.GATSBY_API_URL  || (process.env.NODE_ENV === 'production' ? 'https://api.reachsocialgrowth.com' : 'http://localhost:5555');
// TODO: Handle Errors
class UserModel {
  compId;
  usrTkn;
  svcId;
  hdrs;
  igId;
  constructor() {
    this.compId = null;
    this.usrTkn = null;
    this.svcId = null;
    this.hdrs = null;
    this.igId = null;
  }
  setIgId = (igId) => {
    this.igId = igId;
  }
  setHeaders = (headers) => {
    this.hdrs = headers;
  }
  setCompId = (companyId) => {
    this.compId = companyId;
  }
  setSvcId = (serviceId) => {
    this.svcId = serviceId;
  }
  setUsrTkn = (userToken) => {
    this.usrTkn = userToken;
  }
  getAnalytics = async (start = null, end = null) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    const serviceId = this.svcId || await getCurrentServiceId(this);

    try {
      let query = ``;
      if (start && end) {
        query = `?start=${start}&end=${end}`;
      }
      const request = await axios.get(`${newHerokuApi}/service/${serviceId}/analytics${query}`, headers);

      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error retrieving your analytics',
        }
      }
    }
  }
  getSentimentAnalysis = async (mediaId) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    const serviceId = this.svcId || await getCurrentServiceId(this);

    try {
      const request = await axios.get(`${newHerokuApi}/service/${serviceId}/media/${mediaId}/sentiment`, headers);

      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error retrieving media sentiment analysis',
        }
      }
    }
  }
  getInstagramBasedAnalytics = async (start = null, end = null) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    const instaId = await getCurrentServiceIGId(this);
    try {
      let query = ``;
      if (start && end) {
        query = `?start=${start}&end=${end}`;
      }
      const request = await axios.get(`${newHerokuApi}/instagram/${instaId}/analytics${query}`, headers);

      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error retrieving your analytics',
        }
      }
    }
  }
  inviteUser = async (email) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.post(`${newHerokuApi}/invite`, {
        email,
      }, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error inviting user. Please try again.'
        }
      }
    }
  }
  
  getInvite = async (userInviteId) => {
    const headers = await getNoCompanyAuthHeaders()
    try {
      const request = await axios.get(`${newHerokuApi}/invite/${userInviteId}`, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error getting your invite. Please try again.'
        }
      }
    }
  }
  getAllInvites = async () => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.get(`${newHerokuApi}/user/invites`, headers)
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error getting your invites',
        }
      }
    }
  }

  completeInvite = async (userInviteId) => {
    try {
      const headers = await getNoCompanyAuthHeaders();
      const request = await axios.post(`${newHerokuApi}/invite/${userInviteId}/complete`, null, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error completing your invitation. Please contact your admin.'
        }
      }
    }
  }

  rejectUserInvite = async (userInviteId) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.post(`${newHerokuApi}/invite/${userInviteId}/delete`, null, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error deleting invite. Please try again.'
        }
      }
    }
  }

  resendInvite = async (userInviteId) => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.post(`${newHerokuApi}/invite/${userInviteId}/resend`, null, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error resending invite. Please try again.'
        }
      }
    }
  }

  getAllUsersTiedToCompany = async () => {
    const companyId = this.compId || await getCurrentCompanyId(this);
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.get(`${newHerokuApi}/company/${companyId}/users`, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error getting company users. Please try again.'
        }
      }
    }
  }

  getInvites = async () => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.get(`${newHerokuApi}/invites`, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error retrieving invites. Please try again.'
        }
      }
    }
  }

  sendWelcomeEmail = async () => {
    const headers = this.hdrs || await getAuthHeaders(this);
    try {
      const request = await axios.post(`${newHerokuApi}/user/onboarded`, null, headers);
      return request.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error sending a welcome email',
        }
      }
    }
  }

  updateServiceData = async (params, service_id = null) => {
    const serviceId = service_id || this.svcId || await getCurrentServiceId(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    const db = firebase.firestore();
    await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId).update({
        'data.ig_engagement': params.ig_engagement,
        'data.ig_likes_per_day': params.ig_likes_per_day,
        'data.ig_comments_per_day': params.ig_comments_per_day,
        'data.ig_total_likes': params.ig_total_likes,
        'data.ig_total_comments': params.ig_total_comments,
        'data.ig_total_followers': params.ig_total_followers,
      });
    return true;
  }
  getServiceToken = async (serviceId) => {
    const companyId = this.compId || await getCurrentCompanyId(this);
    const db = firebase.firestore();
    const service = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId).get();
    const serviceData = service.data();
    return serviceData.data.lltoken;
  }
  createCompany = async (companyName, companyIndustry) => {
    const userId = await getCurrentUserId();
    try {
      const createCompany = await axios.post(`${newHerokuApi}/company/new`, {
        user_id: userId,
        company_name: companyName,
        company_industry: companyIndustry,
      });
      return createCompany.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error with the request. Please try again.',
        };
      }
    }
  }
  getAllServices = async () => {
    const header = await getAuthHeaders();
    const companyId = await getCurrentCompanyId();
    try {
      const getAllServicesResponse = await axios.get(`${newHerokuApi}/company/${companyId}/services`, header);
      return getAllServicesResponse.data;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'Could not find all services',
        }
      }
    }
  }
  getAllCompanies = async () => {
    const userId = await getCurrentUserId();
    const db = firebase.firestore();
    const query = db.collection("companyUsers")
      .where("userId", "==", userId);
    const companies = await query.get();
    return companies.docs;
  }
  getCurrentCompanyId = async () => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);

    const getCurrentCompany = await axios.get(`${newHerokuApi}/company/${companyId}`, header);
    return getCurrentCompany.data;
  }
  getAllComments = async () => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    const serviceId = this.svcId || await getCurrentServiceId(this);
    const getAllCommentsResponse = await axios.get(`${newHerokuApi}/company/${companyId}/service/${serviceId}/comments`, header);
    return getAllCommentsResponse.data;
  }
  getCompanyById = async (id) => {
    const header = this.hdrs || await getAuthHeaders(this);
    const getCompanyInfo = await axios.get(`${newHerokuApi}/company/${id}`, header);

    return getCompanyInfo.data;
  }
  approveResponse = async (response) => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    const serviceId = this.svcId || await getCurrentServiceId(this);
    const resp = await axios.post(`${newHerokuApi}/company/${companyId}/service/${serviceId}/comment/${response.id}/approve`, null, header);
    return resp;
  }
  rejectResponse = async (response) => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    const serviceId = this.svcId || await getCurrentServiceId(this);
    const resp = await axios.post(`${newHerokuApi}/company/${companyId}/service/${serviceId}/comment/${response.id}/reject`, null, header);
    return resp;
  }
  generateLongLivedTokenForService = async (serviceId) => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    const shortLivedToken = await getShortLivedToken(serviceId, companyId);
    try {
      const updateTokenResponse = await axios.post(`${newHerokuApi}/instagram/update-long-lived`, {
        companyId,
        serviceId,
        shortLivedToken,
      }, header);
      return updateTokenResponse;
    } catch (err) {
      if (err) {
        return {
          invalid: true,
          message: 'There was an error generating your long lived token. Please try again.'
        }
      }
    }
  }
  createService = async (instaServiceData) => {
    const header = this.hdrs || await getAuthHeaders(this);
    const companyId = this.compId || await getCurrentCompanyId(this);
    try {
      const createService = await axios.post(`${newHerokuApi}/instagram/new`, {
        service: instaServiceData,
        companyId, 
      }, header);
      return createService;
    } catch (err) {
      if (err) {
        console.log(err);
        return {
          invalid: true,
          message: 'There is already a company with the same id. Please try again.',
        };
      }
    }
  }

  subscribeToApp = async (serviceId) => {
    // Get subscribed Apps
    const companyId = this.compId || await getCurrentCompanyId(this);
    const token = await getCurrentUserToken(serviceId, companyId);
    const pageId = await getCurrentPageId(serviceId, companyId);
    
    const userId = await getCurrentUserFBId(serviceId, companyId);
    const pageAccess = await axios.get(`${fbApiUrl}/${userId}/accounts?access_token=${token}`);
    // Should return a list of pages with representative access tokens
    const pageAccessToken = pageAccess.data.data.filter((account) => account.id === pageId)[0].access_token;
    // const pageAccessToken = await this.getServiceToken(serviceId);
    const getSubscribedApps = await axios.get(`${fbApiUrl}/${pageId}/subscribed_apps?access_token=${pageAccessToken}`)
    if (getSubscribedApps.data.data.length < 1) {
      const subscribeApp = await axios.post(`${fbApiUrl}/${pageId}/subscribed_apps?access_token=${pageAccessToken}`, {
        subscribed_fields: 'email'
      });
    }
    // If this is empty or false or whatever we need to subscribe.

    return true;
  }

  getFacebookPages = async (token) => {
    const response = await axios.get(`${fbApiUrl}/me?access_token=${token}`);
    const accounts = await axios.get(`${fbApiUrl}/${response.data.id}/accounts?access_token=${token}`);
    return accounts.data;
  }

  getServiceData = async () => {
    const companyId = this.compId || await getCurrentCompanyId(this);
    const serviceId = this.svcId ||  await getCurrentServiceId(this);
    const db = firebase.firestore();
    const data = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId).get();

    return data.data() !== undefined ? data.data().data : [];
  }
  
  getUserMeta = async () => {
    const token = await getCurrentUserToken();
    const response = await axios.get(`${fbApiUrl}/me/accounts?access_token=${token}`);
    // const instaId = response.data.data[0].id;
    const serviceData = await this.getServiceData();
    const accountId = serviceData.fb_page_id;
    const newResponse = await axios.get(`${fbApiUrl}/${accountId}?fields=username,name&access_token=${token}`);
    return newResponse.data;
  }

  getUsersPages = async () => {
    const token = await getCurrentUserToken();
    const response = await axios.get(`${fbApiUrl}/me/accounts?access_token=${token}`);
    return response.data;
  }

  getPageInstagramAccount = async (pageId, tkn) => {
    let token = tkn;
    if (!tkn) {
      token = await getCurrentUserToken();
    }
    try {
      const response = await axios.get(`${fbApiUrl}/${pageId}?fields=instagram_business_account&access_token=${token}`);
      return response.data;
    } catch (err) {
      return {
        error: "There was an error getting your instagram business account"
      };
    }
    
    // save this id
    
  }

  getFollowerCount = async (businessId) => {
    const token = await getCurrentUserToken();
    const currentDate = Math.round(new Date().getTime() / 1000);
    // max since date is 1 month or 30 days
    const sinceDate = currentDate - thirtyDaysUnix;
    const response = await axios.get(
      `${fbApiUrl}/${businessId}/insights?metric=follower_count&period=day&since=${sinceDate}&until=${currentDate}&access_token=${token}`,
    );
    return response.data;
  }

  getImpressionCount = async (businessId) => {
    const token = await getCurrentUserToken();
    const currentDate = Math.round(new Date().getTime() / 1000);
    // max since date is 1 month or 30 days
    const sinceDate = currentDate - thirtyDaysUnix;
    const response = await axios.get(
      `${fbApiUrl}/${businessId}/insights?metric=impressions&period=day&since=${sinceDate}&until=${currentDate}&access_token=${token}`,
    );
    return response.data;
  }

  getAllInsights = async (businessId) => {
    const token = await getCurrentUserToken();
    const currentDate = Math.round(new Date().getTime() / 1000);
    // max since date is 1 month or 30 days
    const sinceDate = currentDate - thirtyDaysUnix;
    const response = await axios.get(
      `${fbApiUrl}/${businessId}/insights?metric=follower_count,impressions,reach,profile_views,email_contacts,website_clicks,text_message_clicks&period=day&since=${sinceDate}&until=${currentDate}&access_token=${token}`,
    );
    return response.data;
  }

  getInsightsFromMedia = async (mediaObjects, token) => Promise.all(
    mediaObjects.map(async (obj) => {
      const resp = await axios.get(
        `${fbApiUrl}/${obj}?fields=comments_count,like_count&access_token=${token}`,
      );
      return resp.data;
    }),
  ).then((resolvedValues) => {
    const arrayOfLikes = resolvedValues.map(value => value.like_count);
    const arrayOfComments = resolvedValues.map(value => value.comments_count);
    const totalLikes = arrayOfLikes.reduce((total, currentValue) => total + currentValue);
    const totalComments = arrayOfComments.reduce((total, currentValue) => total + currentValue);
    return {
      totalLikes,
      totalComments,
      mediaCount: mediaObjects.length,
      date: new Date().toISOString(),
    };
  });

  getUserMediaInsights = async (businessId, fb_access_token) => {
    const token = fb_access_token || await getCurrentUserToken();
    const currentDate = Math.round(new Date().getTime() / 1000);

    const sinceDate = currentDate - thirtyDaysUnix;
    const response = await axios.get(
      `${fbApiUrl}/${businessId}/media?access_token=${token}`,
    );
    // Get array of media objects
    const mediaObjects = response.data.data.map(value => value.id);
    const combinedLikesAndComments = await this.getInsightsFromMedia(mediaObjects, token);
    return combinedLikesAndComments;
  }
  
  getMediaDataFromIds = async (mediaObjects, token) => Promise.all(
    mediaObjects.map(async (media) => {
      const mediaResponse = await axios.get(
        `${fbApiUrl}/${media.id}?access_token=${token}&fields=comments,comments_count,is_comment_enabled,like_count,media_type,media_url,timestamp,username,children,caption,id,ig_id,thumbnail_url`,
      );
      return mediaResponse.data;
    }),
  ).then(resolvedValues => resolvedValues)

  getAllUserMediaObjects = async (businessId) => {
    const token = await getCurrentUserToken();
    const response = await axios.get(
      `${fbApiUrl}/${businessId}/media?access_token=${token}&limit=12`,
    );
    // List of images
    const results = await this.getMediaDataFromIds(response.data.data, token);
    const data = {
      data: results,
      next: undefined,
    };
    if (response !== undefined && response.data !== undefined && response.data.paging !== undefined && response.data.paging.next !== undefined) {
      data.next = response.data.paging.next;
    }
    return data;
  };

  getNextListOfItems = async (url) => {
    const token = await getCurrentUserToken();
    const response = await axios.get(`${url}`);
    const results = await this.getMediaDataFromIds(response.data.data, token);
    const data = {
      data: results,
      next: undefined,
    };
    if (response.data && response.data.paging && response.data.paging.next !== undefined) {
      data.next = response.data.paging.next;
    }
    return data;
  };

  getTotalFollowerCount = async (businessId, fb_access_token) => {
    const token = fb_access_token || await getCurrentUserToken();
    const currentDate = Math.round(new Date().getTime() / 1000);

    const sinceDate = currentDate - thirtyDaysUnix;
    const response = await axios.get(
      `${fbApiUrl}/${businessId}?fields=followers_count&access_token=${token}`,
    );
    return {
      followersCount: response.data.followers_count,
      date: new Date().toISOString(),
    };
  }

  getCommentMeta = async (commentId) => {
    const token = await getCurrentUserToken();
    const response = await axios.get(
      `${fbApiUrl}/${commentId}?fields=replies,text,timestamp,like_count,id,username&access_token=${token}`,
    );
    return {
      comment: response.data,
    };
  };

  getMediaInsights = async (mediaId, hasCarousel = false, hasVideo = false) => {
    const token = await getCurrentUserToken();
    let carousel = '';
    let video = '';
    if (hasCarousel) {
      carousel = `,carousel_album_engagement,carousel_album_impressions,carousel_album_reach,carousel_album_saved,carousel_album_video_views`;
    }
    if (hasVideo) {
      video = `,video_views`
    }
    try {
      const response = await axios.get(
        `${fbApiUrl}/${mediaId}/insights?metric=engagement,impressions,reach,saved${video}${carousel}&access_token=${token}`,
      );
      return response.data;
    } catch (err) {
      return {
        invalid: true,
        message: err.response.data.error.message,
      }
    }
    
  }

  getReplyMeta = async (commentId) => {
    const token = await getCurrentUserToken();
    try {
      const response = await axios.get(
        `${fbApiUrl}/${commentId}?fields=text,timestamp,id,username&access_token=${token}`,
      );
      return {
        comment: response.data,
      };
    } catch (err) {
      console.log(err.error.message);
    }
  };

  updateComment = async (commentId, mediaId, isReply, message) => {
    const token = await getCurrentUserToken();
    if (isReply && commentId !== undefined) {
      const response = await axios.post(
        `${fbApiUrl}/${commentId}/replies?message=${message}&access_token=${token}`,
      );
      return response.data;
    }
    const response = await axios.post(
      `${fbApiUrl}/${mediaId}/comments?message=${message}&access_token=${token}`,
    );
    return response.data;

    // {ig-comment-id}/replies?message={message}
    // /{ig-media-id}/comments?message={message}
  };

  deleteComment = async (commentId) => {
    const token = await getCurrentUserToken();
    const response = await axios.delete(
      `${fbApiUrl}/${commentId}?access_token=${token}`,
    );
    return response.data;
  };
}

export default UserModel;
