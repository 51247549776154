import firebase from 'firebase/app';
import 'firebase/firestore';
// const matchCompanyId = async (inviteId) => {
//   const data = await firebase.firestore()
//     .collection('companyInvites')
//     .doc(inviteId)
//     .get();

//   return data.data();
// }
const getCurrentServiceId = async (model = null) => {
  const data = await firebase.firestore()
  .collection('users')
  .doc(firebase.auth().currentUser.uid)
  .get();
  if (model !== null) {
    model.setSvcId(data.data().current.current_service_id)
  }
  return data.data().current.current_service_id;

}
const getCurrentServiceIGId = async (model = null, refresh = false) => {
  if (model && refresh === false) {
    if (model.igId) {
      return model.igId;
    }
    const companyId = model.compId || await getCurrentCompanyId();
    const serviceId = model.svcId || await getCurrentServiceId();
    const db = firebase.firestore();
    const service = await db.collection("companies")
        .doc(companyId)
        .collection("services")
        .doc(serviceId)
        .get();
    const serviceData = service.data();
    model.setIgId(serviceData.data.instagram_id);
    return serviceData.data.instagram_id;
  } else if (refresh && model) {
    const companyId = model.compId || await getCurrentCompanyId();
    const serviceId = model.svcId || await getCurrentServiceId();
    const db = firebase.firestore();
    const service = await db.collection("companies")
        .doc(companyId)
        .collection("services")
        .doc(serviceId)
        .get();
    const serviceData = service.data();
    model.setIgId(serviceData.data.instagram_id);
    return serviceData.data.instagram_id;
  } else {
    const companyId = await getCurrentCompanyId();
    const serviceId = await getCurrentServiceId();
    const db = firebase.firestore();
    const service = await db.collection("companies")
        .doc(companyId)
        .collection("services")
        .doc(serviceId)
        .get();
    const serviceData = service.data();
    return serviceData.data.instagram_id;
  }
}
const getCurrentCompanyId = async (model = null) => {
  if (model) {
    if (model.compId) {
      return model.compId;
    }
    const data = await firebase.firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get();
    model.setCompId(data.data().current.current_company_id)
    return data.data().current.current_company_id;
  }
  const data = await firebase.firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid)
    .get();
  return data.data().current.current_company_id;
}
const setCurrentCompanyId = async (companyId, model = null) => {
  const db = firebase.firestore();
  await db.collection('users')
    .doc(firebase.auth().currentUser.uid)
    .update({
      "current.current_company_id": companyId,
    });
  if (model) {
    model.setCompId(companyId);
    getAuthHeaders(model);
  }
}
const setCurrentServiceId = async (serviceId, model = null) => {
  const db = firebase.firestore();
  await db.collection('users')
    .doc(firebase.auth().currentUser.uid)
    .update({
      "current.current_service_id": serviceId,
    });
  if (model) {
    model.setSvcId(serviceId);
  }
}

const getCompanyId = async () => {
  const userId = firebase.auth().currentUser.uid;
  const db = firebase.firestore();
  const query = await db.collection('companyUsers').where('userId', '==', userId).get();
  if (query.empty || !query.docs || query.docs.length === 0) {
    return undefined;
  }
  return query.docs[0].data().companyId;
}
const getAuthHeaders = async (model = null) => {
  const userToken = await firebase.auth().currentUser.getIdToken(true).catch(function(error) {
      throw error
    });
  
  const companyId = await getCurrentCompanyId();
  
  const config = {
      headers: {
        'user-token': userToken,
        'company-id': companyId,
      }
    }
  if (model) {
    model.setHeaders(config);
    model.setCompId(companyId);
  }
  return config;
}
const getNoCompanyAuthHeaders = async (email) => {
  const userToken = await firebase.auth().currentUser.getIdToken(true).catch((error) => {
    throw error;
  });

  let config = {
    headers: {
      'user-token': userToken,
    }
  }
  return config;
}
const getShortLivedToken = async (service_id, company_id) => {
  const serviceId = service_id || await getCurrentServiceId()
  const companyId = company_id || await getCompanyId()
  const db = firebase.firestore();
  const service = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId).get();
  const serviceData = service.data();
  return serviceData.data.fb_access_token;
}
const getCurrentUserToken = async (service_id = null, company_id = null) => {
  // TODO: set up current service id
  const serviceId = service_id || await getCurrentServiceId()
  const companyId = company_id || await getCurrentCompanyId()
  const db = firebase.firestore();
  const service = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId)
      .get();
  const serviceData = service.data();

  return serviceData.data.lltoken;
};
const getCurrentUserId = async () => firebase.auth().currentUser.uid;

const getCurrentUserLLToken = async () => {
  const db = firebase.firestore();
  const snapshot = await db.collection('users').doc(firebase.auth().currentUser.uid).get();
  return snapshot.data().fb_long_lived_token;
};

const getCurrentPageId = async (serviceId, companyId) => {
  // change this.
  const db = firebase.firestore();
  const service = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId)
      .get();
  const serviceData = service.data();
  return serviceData.data.fb_page_id;
};

const getCurrentUserFBId = async (serviceId, companyId) => {
  const db = firebase.firestore();
  const service = await db.collection("companies")
      .doc(companyId)
      .collection("services")
      .doc(serviceId)
      .get();
  const serviceData = service.data();
  return serviceData.data.fb_user_id;
};

const getCurrentUserInstagramId = async () => {
  const db = firebase.firestore();
  const snapshot = await db.collection('users').doc(firebase.auth().currentUser.uid).get();
  return snapshot.data().instagram_id;
}
export {
  getCurrentUserToken,
  getCurrentUserFBId,
  getCurrentUserLLToken,
  getCurrentPageId,
  getCurrentUserInstagramId,
  getCurrentUserId,
  getAuthHeaders,
  getCompanyId,
  setCurrentServiceId,
  getCurrentServiceId,
  setCurrentCompanyId,
  getCurrentCompanyId,
  getShortLivedToken,
  getNoCompanyAuthHeaders,
  getCurrentServiceIGId,
};
